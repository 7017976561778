/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineStore, storeToRefs } from 'pinia'
import { APAM_VERSION, APAM_DISMISSAL_MAX_AGE } from '@/config/config-front-end'
import { data as staticData } from '@/static-data/data'
import type { ToastServiceMethods } from 'primevue/toastservice'
import { useToolsStore } from '@/stores/tools'
import { shallowRef } from 'vue'
import type { FixCartModalParams, ItemInfo } from '@/types/shared.types'

let toastInstance: ToastServiceMethods | null = null

interface ModalParams {
    opened: boolean,
    title: string;
    message: string;
    image: string | null;
    buttonLabel: string;
}

interface SelectionStatusParams {
    allSelected: boolean,
    partiallySelected: boolean,
    noSelected: boolean,
    topic: string | null
}

interface DataItem {
    items: any[];
    matching: any[];
    updated?: number;
    fetched: number;
    activatedFeatures?: any[];
}

interface Selectors {
    zone: string[]
    country: string[]
    division: string[]
    brand: string[]
    framework: string[]
}

interface AppState {
    isLoading: boolean,
    lastDismissal: number;
    loaderVisible: boolean;
    app: {
        page: string;
        offline: boolean;
        authenticationTime: number;
        authenticationEmail: string;
        modal: ModalParams;
        fixCartModal: FixCartModalParams;
    },
    data: {
        version: number;
        updated: number;
        md5: string;
        zone: string[];
        country: string[];
        division: string[];
        brand: string[];
        framework: string[];
        url: string[];
        master: DataItem;
        tools: DataItem;
        topics: DataItem;
        fetched: number;
    };
    selectors: {
        zone: string[]
        country: string[]
        division: string[]
        brand: string[]
        framework: string[]
    }
    filters: {
        zone: string[]
        country: string[]
        division: string[]
        brand: string[]
        framework: string[]
    }
    cart: string[],
    fixCart: Record<string, any>;
    selectionStatus: SelectionStatusParams;
    actions: Record<string, any>;
    status: Record<string, any>;
    flags: {
        gotNewData: {
            [key: string]: boolean;
        };
        scoreUpdated: Record<string, any>;
        renderCart: boolean;
        updateStorage: boolean;
    };
    sorting: {
        md5: string;
        listName: string;
        column: string;
        order: string | null;
        indices: number[] | null;
    };
}
const getDefaultState = () => ({
    data: {
        ...staticData,
        fetched: staticData.updated,
        version: APAM_VERSION
    }
})

export const useMainStore = defineStore('main', {
    state: (): AppState => ({
        isLoading: false,
        lastDismissal: 0,
        loaderVisible: false,
        app: {
            page: '',
            offline: false,
            authenticationTime: 0,
            authenticationEmail: '',
            modal: {
                opened: false,
                title: '',
                message: '',
                buttonLabel: '',
                image: null
            },
            fixCartModal: {
                isOpen: false,
                items: [],
                rules: [],
                item: null,
                user: null,
                tool: ''
            }
        },
        data: shallowRef({
            version: APAM_VERSION,
            updated: 0,
            md5: '',
            zone: [],
            country: [],
            division: [],
            brand: [],
            framework: [],
            url: [],
            master: {
                items: [],
                matching: [],
                fetched: 0,
                activatedFeatures: [],
            },
            tools: {
                items: [],
                matching: [],
                updated: 0,
                fetched: 0
            },
            topics: {
                items: [],
                matching: [],
                updated: 0,
                fetched: 0
            },
            fetched: 0
        }),
        selectors: {
            zone: [],
            country: [],
            division: [],
            brand: [],
            framework: []
        },
        filters: {
            zone: [],
            country: [],
            division: [],
            brand: [],
            framework: []
        },
        cart: [],
        fixCart: [],
        selectionStatus: {
            allSelected: false,
            partiallySelected: false,
            noSelected: true,
            topic: null
        },
        actions: {},
        status: {},
        flags: {
            gotNewData: {
                apam: false,
                tools: false,
                topics: false
            },
            scoreUpdated: {},
            renderCart: false,
            updateStorage: false
        },
        sorting: {
            md5: '',
            listName: '',
            column: '',
            order: '',
            indices: []
        }
    }),
    getters: {
        getRenderCartFlag: (state) => state.flags.renderCart,
    },
    actions: {
        setLoading(loading: boolean) {
            this.isLoading = loading
        },

        updatePage(page: string) {
            this.app.page = page
            const toolsStore = useToolsStore()
            toolsStore.setPage(page)
        },

        updateOffline(offline: boolean) {
            if (offline !== this.app.offline) {
                this.showToast(
                    `You are now ${offline ? "offline" : "online"}.`,
                    offline ? 'warn' : 'info'
                )
            }
            this.app.offline = offline
        },

        setToastInstance(toast: ToastServiceMethods) {
            toastInstance = toast
        },

        showToast(message: string, severity: 'success' | 'info' | 'warn' | 'error' = 'info') {
            if (!toastInstance) {
                console.error('Toast service not initialized')
                return
            }

            toastInstance.add({
                severity,
                summary: message,
                life: 3000,
                group: 'br'
            })
        },

        openModal(title: string, message: string, buttonLabel: string, image: string | null = null) {
            this.app.modal = {
                opened: true,
                title,
                message,
                buttonLabel,
                image
            }
        },

        closeModal() {
            this.app.modal.opened = false
        },

        setAuthenticationTime(timestamp: number) {
            this.app.authenticationTime = timestamp
        },

        resetAuthenticationTime() {
            this.setAuthenticationTime(0)
        },

        setAuthenticationEmail(email: string) {
            this.app.authenticationEmail = email
        },

        async fetchData(listName: string, url: string) {
            const _fetchCurrentData = async (listName: string, url: string, isFirstTime: boolean = true) => {
                try {
                    const response = await fetch(url);
                    const json = await response.json();

                    if (Object.keys(json).length === 0) {
                        console.log("Error: got empty data");
                        this.openModal('Error', 'Failed to refresh data (got empty response).\nClick OK to continue.', 'OK');
                    } else if (!("version" in json) || json.version < APAM_VERSION) {
                        if (isFirstTime) {
                            console.log(`Got incompatible data (remote: '${json.version}' is older than local: '${APAM_VERSION}').`);
                            this.openModal('Update', 'The data that was fetched is outdated.\nTrying again…', 'OK');
                            await _fetchCurrentData(listName, url, false);
                        } else {
                            console.log(`Still got incompatible data (remote: '${json.version}' vs. local: '${APAM_VERSION}').`);
                            this.openModal('Update', "The data fetched is still outdated.\nIt's an unexpected behavior, contact the APAM developers team to investigate it.", 'OK');
                        }
                    } else if (json.version > APAM_VERSION) {
                        const now = Date.now();
                        if ((now - this.lastDismissal) > APAM_DISMISSAL_MAX_AGE) {
                            this.lastDismissal = now;
                            if (isFirstTime) {
                                console.log(`Got incompatible data (remote: '${json.version}' is more recent than local: '${APAM_VERSION}').`);
                                this.openModal('Update', 'A new version of the app is available! Please click Refresh to update, or OK to continue.', 'OK');
                            } else {
                                console.log(`Still got incompatible application (remote: '${json.version}' vs. local: '${APAM_VERSION}').`);
                                this.openModal('Update', "The application updated is still outdated.\nIt's an unexpected behavior, contact the APAM developers team to investigate it.", 'OK');
                            }
                        }
                    } else {
                        if (!isFirstTime) {
                            this.openModal('Update', 'Successfully fetched fresh data!', 'OK');
                        }

                        const now = Date.now();
                        json.fetched = now;

                        for (const attribute in json) {
                            if (json[attribute] !== null && typeof json[attribute] === "object" && "items" in json[attribute]) {
                                json[attribute].fetched = now;
                            }
                        }

                        const updates = Object.entries(json).reduce((acc: Record<string, any>, [key, value]) => {
                            if (JSON.stringify(this.data[key]) !== JSON.stringify(value)) {
                                acc[key] = value;
                            }
                            return acc;
                        }, {});

                        if (Object.keys(updates).length > 0) {
                            this.$patch((state) => {
                                state.data = {
                                    ...state.data,
                                    ...updates,
                                    fetched: Date.now()
                                }
                            });
                        }

                        if ("tools" in json && "topics" in json) {
                            this.dataIsNew("apam");
                        } else {
                            this.dataIsNew(listName);
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            };

            await _fetchCurrentData(listName, url);
        },

        populateSelectors(selectors: Partial<Selectors>) {
            this.selectors = {
                ...this.selectors,
                ...selectors
            }
        },

        resetSelectors() {
            const data = this.data
            this.selectors = {
                zone: data.zone,
                country: data.country,
                division: data.division,
                brand: data.brand,
                framework: this.selectors.framework
            }
        },

        setFilters(filters: Partial<Record<string, any>>) {
            const newFilters = {
                zone: this.filters.zone || [],
                country: this.filters.country || [],
                division: this.filters.division || [],
                brand: this.filters.brand || [],
                framework: this.filters.framework || []
            }

            Object.entries(filters).forEach(([key, value]) => {
                newFilters[key] = value
            })

            this.filters = newFilters
        },

        resetFilters() {
            this.filters = {
                zone: [],
                country: [],
                division: [],
                brand: [],
                framework: []
            }
        },

        addToCart(urls: string[]) {
            this.cart = [...new Set([...this.cart, ...urls])]
        },

        removeFromCart(items: string[]) {
            this.cart = this.cart.filter((item: any) => !items.includes(item));
        },

        resetCart() {
            this.cart = [];
        },

        toggleSelectAll(urls: string[]) {
            const currentCart = this.cart

            if (urls.every(url => currentCart.includes(url))) {
                this.cart = []
            } else {
                this.cart = [...urls]
            }

            if (this.cart.length === 0) {
                this.flags.renderCart = false
            }
        },

        addToFixCart(items: []) {
            this.fixCart.push(...items);
        },

        removeFromFixCart(items: string[]) {
            this.fixCart = this.fixCart.filter((item: string) => !items.includes(item));
        },

        resetFixCart() {
            this.fixCart = [];
        },

        deleteItem(rule: string, index?: number) {
            if (typeof index !== 'undefined') {
                const items = [...this.app.fixCartModal.items[rule]]
                items.splice(index, 1)

                this.app.fixCartModal.items[rule] = items

                if (items.length === 0) {
                    delete this.app.fixCartModal.items[rule]
                    this.deleteRule(rule)
                }
            } else {
                delete this.app.fixCartModal.items[rule]
                this.deleteRule(rule)
            }
        },

        deleteRule(rule: string) {
            const ruleIndex = this.app.fixCartModal.rules.indexOf(rule)
            if (ruleIndex !== -1) {
                this.app.fixCartModal.rules.splice(ruleIndex, 1)
            }
        },

        updateSelectionStatus(allSelected: boolean, partiallySelected: boolean, noSelected: boolean, topic: string | null = null) {
            this.selectionStatus = { allSelected, partiallySelected, noSelected, topic };
        },

        setStatus(page: string, message: string) {
            this.status[page] = message;
        },

        dataIsNew(listName: string) {
            this.setDataStatus(listName, true);
        },

        dataIsOld(listName: string) {
            this.setDataStatus(listName, false);
        },

        renderCart() {
            this.flags.renderCart = true;
        },

        dontRenderCart() {
            this.flags.renderCart = false;
        },

        toggleStorageFlag() {
            this.flags.updateStorage = !this.flags.updateStorage;
        },

        startAction(listName: string, name: string, urls: string[]) {
            if (!this.actions[listName]) {
                this.actions[listName] = {};
            }
            if (!this.actions[listName][name]) {
                this.actions[listName][name] = [];
            }
            this.actions[listName][name].push(...urls);
        },

        endAction(listName: string, name: string, urls: string[]) {
            if (this.actions[listName] && this.actions[listName][name]) {
                this.actions[listName][name] = this.actions[listName][name]
                    .filter((url: string) => !urls.includes(url));

                if (this.actions[listName][name].length === 0) {
                    delete this.actions[listName][name];
                }

                if (Object.keys(this.actions[listName]).length === 0) {
                    delete this.actions[listName];
                }
            }
        },

        clearActions() {
            this.actions = [];
        },

        isActionInProgress(nameSelection?: string | string[]): boolean {
            const toolsStore = useToolsStore();
            return this.isItemActionInProgress(toolsStore.currentListName, undefined, nameSelection);
        },

        isItemActionInProgress(listName: string = '', url?: string, nameSelection?: string | string[]): boolean {
            const toolsStore = useToolsStore();

            if (listName === '') {
                listName = toolsStore.currentListName;
            }

            if (listName in this.actions) {
                let names = [];
                const actions = this.actions[listName];
                if (Array.isArray(nameSelection)) {
                    names = nameSelection;
                } else if (nameSelection !== undefined) {
                    names = [nameSelection];
                } else {
                    names = Object.keys(actions);
                }

                for (const name in actions) {
                    if (names.length > 0 && !names.includes(name)) continue;
                    if (actions[name].length > 0) {
                        if (url === undefined) return true;
                        else if (actions[name].includes(url)) return true;
                    }
                }
            }
            return false;
        },

        sortBy(md5: string, listName: string, column: string, order: string | null, indices: number[] | null) {
            this.sorting = { md5, listName, column, order, indices };
        },

        setDataStatus(listName: string, isNew: boolean) {
            const toolsStore = useToolsStore();
            const { getAllListNames } = storeToRefs(toolsStore)
            if (listName === "apam") {
                this.flags.gotNewData.apam = isNew;
                for (const listName of getAllListNames.value) {
                    this.flags.gotNewData[listName] = isNew;
                }
            } else {
                this.flags.gotNewData[listName] = isNew;
            }
        },

        gotNewData() {
            return (listName: string): boolean => {
                if ("apam" in this.flags.gotNewData && this.flags.gotNewData.apam) {
                    return true;
                } else if (typeof listName === 'string' && listName in this.flags.gotNewData) {
                    return this.flags.gotNewData[listName];
                } else {
                    return false;
                }
            };
        },

        gotNewApamData() {
            return (): boolean => {
                if ("apam" in this.flags.gotNewData && this.flags.gotNewData.apam) {
                    return true;
                } else {
                    return false;
                }
            };
        },

        displayModalError(title: string = "Error", message: string = "The application has encountered an unknown error.", image: string | null = null) {
            this.openModal(title, message, 'OK', image);
        },

        displayModalException(exception: any, title: string = "Error", message: string = "The application has encountered an unknown error.") {
            let error = exception;
            if ("result" in error && typeof error.result !== 'string') {
                error = error.result;
            }
            if ("error" in error && typeof error.error !== 'string') {
                error = error.error;
            }
            if ("result" in error && typeof error.result !== 'string') {
                error = error.result;
            }

            if (typeof error == 'string') message = error;
            else if ("message" in error && typeof error.message == 'string') message = error.message;
            else if ("details" in error && typeof error.details == 'string') message = error.details;
            this.displayModalError(title, message.replace(". ", ".\n\n"));
        },

        setLoaderVisible(visible: boolean) {
            this.loaderVisible = visible;
        },

        openFixCartModal(data: {
            items: ItemInfo[]
            rules: string[]
            item: ItemInfo
            user: string
            tool: string
        }) {
            this.app.fixCartModal = {
                isOpen: true,
                ...data
            }
        },

        resetFixCartModal() {
            this.app.fixCartModal = {
                isOpen: false,
                items: [],
                rules: [],
                item: null,
                user: null,
                tool: ''
            }
        },
    },
    persist: {
        key: 'state',
        storage: localStorage,
        beforeHydrate: (state) => {
            const storedVersion = localStorage.getItem('stateVersion')

            if (!storedVersion || storedVersion !== APAM_VERSION.toString()) {
                console.log('Creating initial state from scratch…')
                return {
                    ...state,
                    ...getDefaultState()
                }
            }

            if (state.store.data.version !== APAM_VERSION) {
                console.log('Data version mismatch, resetting state…')

                return {
                    ...state,
                    ...getDefaultState()
                }
            }

            return state
        },
        afterHydrate: () => {
            localStorage.setItem('stateVersion', APAM_VERSION.toString())
        }
    }
})